<template>
	<b-modal id="add-storage-location" title="Add Storage Location" ref="modal" ok-title="Add" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-form-group label="Company">
					<v-select name="Company" class="style-chooser" label="text" :options="companyOptions"
						:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a company
							</em>
						</template>
					</v-select>
					<span v-show="errors.has('Company')" class="help-block">{{
						errors.first('Company')
					}}</span>
				</b-form-group>

				<b-form-group label="Name" label-for="name"
					description="Please enter the non-abbreviated name for the storage location">
					<b-form-input id="name" name="Name" type="text" v-model="form.name" v-validate="{
						required: true,
						regex: locationNameRegex,
					}" placeholder="Name" maxlength="100" />
					<span v-show="errors.has('Name')" class="help-block">{{
						errors.first('Name')
					}}</span>
				</b-form-group>

				<b-form-group label="Description" label-for="description" description="">
					<b-form-input id="description" name="Description" type="text" v-model="form.description" v-validate="{
						required: false,
						regex: remarksRegex,
					}" placeholder="Description" maxlength="200" />
					<span v-show="errors.has('Description')" class="help-block">
						{{ errors.first('Description') }}
					</span>
				</b-form-group>

				<b-form-group label="Address" label-for="address"
					description="Please enter the complete address of the storage location">
					<b-form-input id="address" name="Address" type="text" v-model="form.address" v-validate="{
						required: true,
						regex: addressRegex,
					}" required placeholder="Address" />
					<span v-show="errors.has('Address')" class="help-block">{{
						errors.first('Address')
					}}</span>
				</b-form-group>

				<b-form-group label="Geographical Location" label-for="geoaddress"
					description="This is the exact coordinates that will be used to locate in the map">
					<b-row class="my-2">
						<b-col lg="6" md="6" sm="12">
							<b-form-group description="Latitude">
								<b-form-input id="latitude" name="Latitude" type="number" class="numFont"
									v-model="form.geoaddress.latitude"
									v-validate="'required|min_value:-90|max_value:90'" min="-90" max="90" step="0.01"
									onwheel="this.blur()" placeholder="0" />
							</b-form-group>
							<span v-show="errors.has('Latitude')" class="help-block">{{
								errors.first('Latitude')
							}}</span>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group description="Longitude">
								<b-form-input id="longitude" name="Longitude" type="number" class="numFont"
									v-model="form.geoaddress.longitude"
									v-validate="'required|min_value:-180|max_value:180'" min="-180" max="180"
									step="0.01" onwheel="this.blur()" placeholder="0" />
							</b-form-group>
							<span v-show="errors.has('Longitude')" class="help-block">{{
								errors.first('Longitude')
							}}</span>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-link href="https://www.latlong.net/" target="_blank">
								<i class="icon-info"></i>&nbsp;Generate coordinates here
							</b-link>
						</b-col>
					</b-row>
				</b-form-group>
				<b-form-group label="Area Radius (m)" label-for="areaRadius"
					description="Please enter the allowed area's radius relative to the locations coordinates.">

					<b-form-input id="areaRadius" name="Area Radius" type="number" class="numFont"
						v-model="form.areaRadius" v-validate="'required|min_value:10|max_value:1000'" min="10"
						max="1000" step="1" onwheel="this.blur()" placeholder="Area Radius" />

					<span v-show="errors.has('Area Radius')" class="help-block">{{
						errors.first('Area Radius')
					}}</span>
				</b-form-group>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';
import { StorageLocationUtil } from '@/utils/storageLocationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & DAO
import storageLocationApi from '@/api/storageLocationApi';
import companyDAO from '@/database/companies';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebase from 'firebase/app';
import _ from 'lodash';

export default {
	name: 'add-storage-location',
	components: {
		Loading,
	},
	props: {
		companyFilter: {
			type: Object,
			required: true,
		},
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				name: '',
				description: '',
				company: null,
				companyId: '',
				address: '',
				geoaddress: {
					latitude: null,
					longitude: null,
				},
				areaRadius: 100,
				isActive: 'true',
			},
			selCompany: config.companyDefaultValue,

			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			this.updateCompanySelection();
		},
		'form.areaRadius': function () {
			this.form.areaRadius = parseFloat(this.form.areaRadius);
		}
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
		locationNameRegex() {
			return config.locationNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		addressRegex() {
			return config.addressRegex;
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (StorageLocationUtil.exceedMaximum(this.allCompaniesObj[this.selCompany.id], this.allStorageLocationsObj)) {
				this.$toaster.warning('You have exceeded the allowed no of active storage location for this company.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.name = ValidationUtil.removeExcessWhiteSpace(this.form.name);
			this.form.address = ValidationUtil.removeExcessWhiteSpace(
				this.form.address
			);

			if (ValidationUtil.objectHasField('name', this.form.name, this.allStorageLocationsObj)) {
				this.$toaster.warning('Storage location name already exists.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},

		getStorageLocationObj(param) {
			// pre-process geoaddress
			if (!_.isEmpty(param.geoaddress)) {
				let latitude = LocationUtil.getLatitude(param.geoaddress);
				let longitude = LocationUtil.getLongitude(param.geoaddress);

				param.latitude = latitude;
				param.longitude = longitude;
				param.geoaddress = new firebase.firestore.GeoPoint(
					parseFloat(latitude),
					parseFloat(longitude)
				);
			}

			// update timestamp
			param.dateCreated = DateUtil.getCurrentTimestamp();
			param.createdBy = this.loggedUser.id;
			param.dateUpdated = DateUtil.getCurrentTimestamp();
			param.updatedBy = this.loggedUser.id;

			return { ...param };
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await storageLocationApi.saveStorageLocation(
					this.getStorageLocationObj(this.form),
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					// Update company setup status
					await companyDAO.updateCompanySetupStatus({
						companyId: this.selCompany.id,
						data: {
							hasStorageLocation: true
						}
					});

					this.$toaster.success(`Storage Location "${this.name}" was created successfully.`);

					let result = {
						storageLocation: data.storageLocation,
						connection: data.connection,
						connections: data.connections
					};
					EventBus.$emit('onCloseSaveStorageLocation', result);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error creating storage location "${this.name}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error creating storage location "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateCompanySelection() {
			if (!_.isEmpty(this.selCompany)) {
				this.form.company = this.selCompany.name;
				this.form.companyId = this.selCompany.id;
			}
		},
		onReset() {
			/* Reset our form values */
			this.form.name = '';
			this.form.description = '';
			this.form.company = null;
			this.form.companyId = '';
			this.form.address = '';
			this.form.geoaddress = {
				latitude: null,
				longitude: null,
			};
			this.form.areaRadius = config.areaRadiusDefaultValue;

			// init company
			if (this.companyFilter.id) {
				this.selCompany = this.companyFilter;
				this.updateCompanySelection();
			} else {
				this.selCompany = config.companyDefaultValue;
			}

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>