<template>
	<b-modal id="activate-storage-location" :title="modalTitle" ok-title="Save" ref="modal" @hide="handleClose"
		@ok.prevent="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>{{ isActive === 'true' ? 'deactivate' : 'activate' }}</b>
			this storage location <b>"{{ name }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';
import { StorageLocationUtil } from '@/utils/storageLocationUtil';

// DAO & API
import connectionDAO from '@/database/connections';
import storageLocationApi from '@/api/storageLocationApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebase from 'firebase/app';
import _ from 'lodash';
import { remoteConfig } from '@/config/firebase';


export default {
	name: 'activate-storage-location',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selStorageLocation: {},
			isActive: true,

			loggedUser: this.$store.getters.loggedUser,
			emailSupport: config.adminAccount.TAWI_SUPPORT,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.selStorageLocation.name;
		},
		isForActivation() {
			// NOTE: reversed for display purposes only
			return this.isActive === 'false';
		},
		modalTitle() {
			return this.isActive === 'true' ? 'Deactivate Storage Location' : 'Activate Storage Location';
		},
	},
	async mounted() {
		// retrieve support email from remote config
		try {
			await remoteConfig.fetchAndActivate();
			this.emailSupport = remoteConfig.getString("emailSupport");
		} catch (_error) {
			// assign the default admin email if the variable is not available
			this.emailSupport = config.adminAccount.TAWI_SUPPORT;
		}

		EventBus.$on('onUpdateSelStorageLocation', (storageLocation) => {
			this.selStorageLocation = storageLocation;
			this.isActive = storageLocation.isActive;
		});
	},
	methods: {
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selStorageLocation.isActive = this.isActive;
			this.$store.commit('SET_CURR_STORAGE_LOCATION', this.selStorageLocation);
		},
		handleClose() {
			this.resetStatus();
		},

		async validateDeactivation() {
			let hasActiveConnection = await this.isLocPartOfActiveConnection();
			if (hasActiveConnection) {
				let warningMsg = `Cannot deactivate. Storage Location is included in an active connection.`;
				this.$toaster.warning(warningMsg);
				return false;
			}

			return true;
		},
		// checks if selected storage location is included in an active connection
		async isLocPartOfActiveConnection() {
			let hasExisting = false;

			let connectionsObj = await connectionDAO.getConnectionsByConnectedCompany(this.selStorageLocation.companyId);
			if (connectionsObj && !_.isEmpty(connectionsObj)) {
				_.forEach(connectionsObj, (con) => {
					// get list of storage locations in connection
					let conLocsArr = Object.values(con.storageLocations);
					conLocsArr.forEach((conLocation) => {
						if (conLocation.id === this.selStorageLocation.id && conLocation.isIncluded === 'true') {
							hasExisting = true;
						}
					});
				});
			}

			return hasExisting;
		},

		validateActivation() {
			let companyId = this.selStorageLocation.companyId;
			if (StorageLocationUtil.exceedMaximum(this.allCompaniesObj[companyId], this.allStorageLocationsObj)) {
				let warningMsg = `You have exceeded the allowed no of active storage location of your company. Please contact the administrator (${this.emailSupport}) for assistance.`;
				this.$toaster.warning(warningMsg);
				return false;
			}
			return true;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (this.isActive === 'true') {
				let isValidDeactivation = await this.validateDeactivation();
				if (!isValidDeactivation) {
					return;
				}
			} else if (this.isActive === 'false') {
				let isValidActivation = this.validateActivation();
				if (!isValidActivation) {
					return;
				}
			}

			await this.handleSubmit();
		},

		getStorageLocationObj(param) {
			// pre-process geoaddress
			if (!_.isEmpty(param.geoaddress)) {
				let latitude = LocationUtil.getLatitude(param.geoaddress);
				let longitude = LocationUtil.getLongitude(param.geoaddress);

				param.latitude = latitude;
				param.longitude = longitude;
				param.geoaddress = new firebase.firestore.GeoPoint(
					parseFloat(latitude),
					parseFloat(longitude)
				);
			}

			return { ...param };
		},
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				// update the status
				this.selStorageLocation.isActive = this.toggleStatus(this.isActive);

				let { data } = await storageLocationApi.saveStorageLocation(
					this.getStorageLocationObj(this.selStorageLocation),
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					let successMsg = '';
					if (data.isActive === 'true') {
						successMsg = `Storage Location "${this.name}" is now activated!`;
					} else {
						successMsg = `Storage Location "${this.name}" is now deactivated!`;
					}
					this.$toaster.success(successMsg);

					let result = {
						storageLocation: data.storageLocation,
						connection: data.connection,
						connections: data.connections
					};
					EventBus.$emit('onCloseSaveStorageLocation', result);
					this.$refs.modal.hide();

				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during account activation of the storage location"${this.name}"`;
					} else {
						errorMsg = `There's a problem encountered during account deactivation of the storage location "${this.name}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (_error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during activation of the storage location "${this.name}"`;
				} else {
					errorMsg = `There's a problem encountered during deactivation of the storage location "${this.name}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelStorageLocation');
	},
};
</script>